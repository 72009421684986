import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { UserModel } from '@lms/shared/models';
import { GtmContestService, GtmService } from '@lms/shared/services/app-services';
import { CurrentUserStorage } from '@lms/shared/storages';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AppComponent {
  private gtmContestService = inject(GtmContestService);
  private currentUser = inject(CurrentUserStorage);
  user$: Observable<UserModel | null> = this.currentUser.currentUser$;
  private gtmService = inject(GtmService);

  constructor() {
    this.gtmContestService
      .init()
      .then(() => this.gtmService.initGtmService())
      .catch(error => {
        console.error('Error initializing services:', error);
      });
  }
}
